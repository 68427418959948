<template>
  <div id="map">
    13123132
  </div>
</template>

<script>
  export default {
    created () {
      // let viewer = new Cesium.Viewer('cesiumContainer');
    },
  }
</script>

<style>

</style>
